import React from "react";
import "./Letter.scss";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Letter = () => {
    return (
        <div className="Letter">
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <div className="swipper--div">
                        <img src="./images/logo.png" alt="logo" className="logo" />
                        <h1>Bom, então chegamos a um mês certo? E justo no momento mais conturbado possível, porém eu sinto que devo lhe dizer algumas palavras, você precisa saber disso. Saber o que eu sinto, o que penso e o que espero deste dia em diante, bom vamos lá. Este ultimo mês com toda a certeza do mundo tem sido um dos melhores da minha vida, logo eu que nunca tive sorte com nada, realmente tenho alguém para chamar de meu, alguém que é incrível, que me faz rir, me faz sentir que o amor realmente existe e ainda por cima é o ser mais lindo que eu já conheci, tudo em você me encanta de um jeito que nunca pensei que poderia acontecer, que eu nunca achei que existiriam, mas aí está você, a resposta para tudo que busquei a vida toda.

                        </h1>

                        <p className="sub">Arraste pro lado</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="swipper--div">
                        <img src="./images/logo.png" alt="logo" className="logo" />
                        <h1>Ao seu lado eu descobri que eu consigo expressar os mais sinceros sorrisos, ao seu lado eu descobri o melhor de mim, uma versão que nem eu mesmo conhecia, ao seu lado eu descobri que consigo sentir ciumes de alguém, saudade, principalmente saudade. Ao seu lado eu consegui descobrir o amor e o quão maravilhoso é este sentimento, e eu passei a vida toda achando que algo tão maravilhoso jamais poderia acontecer comigo, como você bem sabe, es minha primeira, e eu espero que sinceramente seja minha última, eu quero trabalhar minha vida toda para que esse desejo se torne realidade. Outro detalhe importante ao seu lado, eu também descobri que consigo pensar em alguém 24 h por dia, ficar preocupado com todos os detalhes possíveis, se você já comeu, se está dormindo bem, o que está fazendo, o que está sentido e como, e isso tudo tem sido incrível e de forma alguma eu quero perder isso.

                        </h1>

                        <p className="sub">Arraste pro lado</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="swipper--div">
                        <img src="./images/logo.png" alt="logo" className="logo" />
                        <h1>Sinto que demorei muito para te encontrar, acredito que tenha chegamos atrasado para ser seu primeiro amor, porem eu também sinto que para mim esse foi o momento certo, o momento onde toda a tempestade e caos que habitavam dentro de mim simplesmente resolveram sair de viagem (e eu espero que não voltem nunca mais). Sei que um mês é pouco tempo, e eu tenho comigo essa missão, quero conhecer todas as suas partes, a amar todas elas, quero conhecer ainda mais essa imensidão que habita dentro do teu peito e tua mente, quero saber de todas as coisas que fazem seu coração pulsar forte, músicas, arte, livros, lugares e sonhos. Quero conhecer cada canto de você, me ensine a te amar para que eu não precise decifrar o que tanto seus olhos ou sua alma querem tanto me dizer.

                        </h1>

                        <p className="sub">Arraste pro lado</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="swipper--div">
                        <img src="./images/logo.png" alt="logo" className="logo" />
                        <h1>Quero que saiba também que prometo te amar em todos os dias, sejam eles ensolarados ou chuvosos, amarei todas as versões de você, da mais frágil até a mais forte, vou aprender a cuidar de ti, pois quero que veja em mim muito mais que um namorado, quero que me veja como seu porto seguro, onde você possa descansar quando alguma tempestade bater em nossa janela, quero fazer parte de seu futuro, fazer parte de teus planos, assim como você já é peça importante do meu.

                        </h1>

                        <p className="sub">Arraste pro lado</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="swipper--div">
                        <img src="./images/logo.png" alt="logo" className="logo" />
                        <h1>Bom uma vez mais gostaria de dizer, você é, foi e sempre será o primeiro e único amor da minha vida, para tudo que irá acontecer, seja bom ou ruim, eu sempre estarei com você, espero que eu tenha passado no seu teste, pois sigo te amando e por fim gostaria de perguntar uma última coisa, e gostaria que você pensasse a respeito, Beatriz Sartori, você quer namorar comigo?
                        </h1>

                        <div className="buttons">
                        <button className="yes">sim</button>
                        <button className="no">não</button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Letter;