import React from "react";
import AllRoutes from "./routes/AllRoutes";

const App = () => {
  return (
    <div className="App">
      <AllRoutes />
    </div>
  )
} 

export default App;