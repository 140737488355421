import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import Letter from "../pages/letter/Letter";


const AllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/letter" element={<Letter />}/>
        </Routes>
    )
}

export default AllRoutes;