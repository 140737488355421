import React from "react";
import "./Home.scss";
import { Link } from "react-router-dom";


const Home = () => {
    return (
        <div className="Home">
            <div className="square--component">
                <img src="./images/logo.png" alt="logo" className="logo"/>
                <h1>Bem Vinda!</h1>
                <h3>bora começar ?</h3>
                <Link to={"/letter"} className="btn__default">SIM!</Link>
            </div>
        </div>
    )
}

export default Home;